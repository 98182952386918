export const customerAndSupplier = {
	title: "Customers and suppliers",
	name: "Trade name",
	namePlaceholder: "Enter your trade name here",
	legalName: "Company name",
	legalNamePlaceholder: "Enter here the company name",
	cpfOrCnpj: "CPF/CNPJ",
	cpfOrCnpjPlaceholder: "Enter the CPF/CNPJ",
	inscricaoEstadual: "State registration",
	inscricaoEstadualPlaceholder: "000.0000-00",
	cep: "Zip code",
	cepPlaceholder: "Enter the zip code",
	state: "State",
	statePlaceholder: "Enter the state",
	city: "City",
	cityPlaceholder: "Enter the city",
	street: "Address",
	streetPlaceholder: "Enter the address",
	streetNumber: "Number",
	streetNumberPlaceholder: "Enter the number",
	complemento: "Complement",
	complementoPlaceholder: "Enter the complement",
	neighborhood: "Neighborhood",
	neighborhoodPlaceholder: "Enter the neighborhood",
	bank: "Bank",
	bankPlaceholder: "Enter the bank",
	agency: "Agency",
	agencyPlaceholder: "0000",
	account: "Account",
	accountPlaceholder: "Enter the account",
	ownerName: "Owner name",
	ownerNamePlaceholder: "Enter account owner",
	bankcpfOrCnpj: "Supplier's CPF/CNPJ",
	bankcpfOrCnpjPlaceholder: "Enter the owner's CPF/CNPJ",
	phone: "Landline",
	cellPhone: "Cell phone",
	email: "E-mail",
	placeholderPhone: "(00) 0000-0000",
	placeholderCellPhone: "(00) 0 0000-0000",
	placeholderEmail: "example@email.com",
	validateField: "Required field",
	wishToAdd: "Do you wish to add?",
	popConfirm: "Do you really want to delete this item?",
	notInformed: "Not informed",
	address: "Address",
	actions: "Actions",
	type: "Type",
	addNewRegister: "New register",
	personalData: "Personal data",
	bankData: "Banking details",
	searchPlaceholder: "Search customer or supplier",
	infoMessage:
		"The registration type is intended to parameterize the financial documents used by a customer, a supplier or both.",
	registerTypes: {
		client: "Customer",
		supplier: "Supplier",
		customerAndSupplier: "Customer and Supplier",
	},
	selectRegistrationType: "Select the registration type",
	modalWarningCancel: {
		title: "Discard changes",
	},
	invalidAgency: "Invalid agency",
	invalidAccount: "Invalid account",
	invalidZipCode: "Invalid zip code",
	invalidEmail: "Invalid e-mail",
	supplierDetails: "Supplier details",
	viewDetails: "View details",
};
